import React from 'react';
import logo from '../../assets/images/BLOG/logo.png';
import './style.css';

const BlogConsentPage = () => {
	return (
		<div id="BlogConsentPage">
            <img className='logo' src={logo} alt='..' /><br />
            <p>Dear Parents or Carers,</p>
            <h4>Letter of consent to download free learning app</h4>
            <p>Thank you for your support for language education. I hope your child will continue to both experience the joy of learning a second language and enhance cross-cultural understanding and communication skills through studying Chinese.</p>
            <p>Using technology in learning is essential for students today and learning Chinese need not be tedious particularly if there is a tool that:</p>
            <ol>
                <li>Recognizes individual students’ preferred learning styles;</li>
                <li>Provides a variety of quality lessons from teachers from all over the world, including authentic language material including images, videos, songs and movie clips on various topics to grab a student’s interest;</li>
                <li>Let’s children listen to spoken texts to hone their listening skills with reading speed suited their learning needs and;</li>
                <li>Let’s teachers create E-learning courseware online to better tailor teaching to suit your child’s needs.</li>
            </ol>
            <p>Ricciwawa is that Chinese learning tool and it is free of charge.</p>
            <p>Here I would like to ask for your permission to download Ricciwawa to your child’s mobile phone. Once downloaded, you can use the search tool on Ricciwawa to track lessons from teachers. They can search “xxxx” on Ricciwawa where I have uploaded xxx.</p>
            <p>Moreover, its creators take online security seriously. Therefore, there are no pop-up ads to interfere with students' learning and content is filtered for students’ safety.</p>
            <p>I hope that students will be able to use this app to do self-study at home at their own time and pace. Please go to https://www.ricciwawa.com/library for more details.</p>
            <p>Sincerely</p>
            <br /><br />
        </div>
	);
};


export default BlogConsentPage;