import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from "react-i18next";
import consentImg from '../../assets/images/BLOG/consent.jpg';
import arrow from '../../assets/images/rightarroworange.svg';
import author from '../../assets/images/BLOG/logo.png';
import clock from '../../assets/images/clock.svg';
import './PostCardBig.scss';

const PostCardBig = () => {
	const [t, i18n] = useTranslation('translation');
	return (
		<>
		
			<Row className='custom-container section-wrapper mx-0 position-relative w-100 d-flex align-items-center'>
			
				<Col className='card-container px-0'>
				<LinkContainer
				to='/blog/consent'
			>
					<Row class='w-100 d-flex align-items-center'>
						<Col lg={6} className='d-flex flex-column justify-content-center'>
							<div className='content-container'>
							
								<h2 className='card-title'>{t('blog_page.loc')}</h2>
								<p className='brief'>
									{t('blog_page.description')}
								</p>
								<div className='author d-flex align-items-center'>
									<div className='author-images'>
										<img src={author} alt='...' className='authorImg' />
									</div>
									<div className='author-info-and-date ms-2'>
										<h4 className='author-name'>Ricciwawa</h4>
										<span className='date'>
											{t('blog_page.date')}{' '}
											<span className='clock-icon'>
												<img src={clock} alt='...' />
											</span>
											{t('blog_page.2mins')}
										</span>
									</div>
								</div>
								
								
							</div>
							<div className='read-button-wrapper d-flex justify-content-end'>
								
									<button className='link-direct'>
										<span>{t('read')}</span>
										<img src={arrow} alt='...' />
									</button>
								
							</div>
						</Col>
						<Col lg={6}>
							<img src={consentImg} alt='...' className='thumb-image' />
						</Col>
					</Row>
					</LinkContainer>
				</Col>
			</Row>
			
		</>
	);
};

export default PostCardBig;
