import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import ContactForm from '../components/ContactForm/ContactForm';
import ContactInfo from '../components/ContactInfo/ContactInfo';

const Contact = () => {
	const [t, i18n] = useTranslation('translation');
	return (
		<div className='contact-page'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{t('contact')} | Ricciwawa</title>
			</Helmet>
			<div className='custom-container contact-content position-relative'>
				<Row>
					<Col xs={12} xxl={7}>
						<ContactForm />
					</Col>
					<Col xs={6} xxl={5} className='d-none d-xxl-block'>
						<ContactInfo />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Contact;
