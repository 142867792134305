import React from 'react';
import { Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import arrow from '../../assets/images/rightarrow.svg';
import './LibraryItem.scss';
import authorImage from "../../assets/images/logo.png";

const LibraryItem = ({
	items,
	showtag,
	showauthor,
	showshorttext,
	showdatetime,
}) => {
	return (
		<>
			{items.map((item) => (
				<Col sm={6} lg={4} className='item-container'>
					<div className='l-item' onClick={() => open(item.url)}>
						<img
							src={item.image}
							alt={item.name}
							className='item-image'
						/>
						<LinkContainer className='item-names'  to="">
							<h2>{item.name}</h2>
						</LinkContainer>
						{showshorttext && (
							<LinkContainer className='library-item d-none d-md-flex' to=''>
								<p className='short-text'>{item.short_txt}</p>
							</LinkContainer>
						)}
						{showauthor && (
							<div className='item-cta d-flex align-items-center ' style={{margin: 0}}>
								{' '}
								<img
									src={authorImage}
									alt={item.author.name}
									className='author-image'
								/>
								<h3 class='author-name'>{item.author.name}</h3>
								<LinkContainer
									to='/'
									className='d-none d-md-block flex-grow ml-auto item-link d-flex align-items-center'
								>
									<span>
										<span className='HSKLevel'>{item.hsk}</span>
										{/* <img src={arrow} alt='Arrow Icon' /> */}
									</span>
								</LinkContainer>
							</div>
						)}
						{showtag && (
							<div className='d-flex justify-content-between align-items-center tt'>
								<div className='tag-section d-flex align-items-center'>
									<span className='first'>Fiction</span> <div></div>{' '}
									<span className='hs'>HSK</span>
								</div>
								
									<span>
										<span className='cat-name'>Read</span>
										<img src={arrow} alt='Arrow Icon' />
									</span>
							</div>
						)}
					</div>
				</Col>
			))}
		</>
	);
};

function open(url) {
	window.location.href = url;
}

export default LibraryItem;
