import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/005.jpg";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog005 = () => {
	return (
		<div id='blogRead'>
            <h3>How to be your students’ favourite teacher</h3>
            
            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>Science and history have proven we learn best with stories, so go with the proven strategy.<br /><br />
            Create a story.<br /><br />
            The story need not be a masterpiece, but it should be relatable to your students.
            <br /><br />
            Even if you simply describe a day buying groceries at a local market, it’s something students can relate to. You might be surprised if stories you think your students will find boring are actually quite interesting to them - and are a rich source of activities and words for your students to learn.
            <br /><br />
            We all have stories we can share. Why not share your stories with your students on Ricciwawa – where then can learn Chinese and learn something about you as well?
            <br /><br /><br /><br /></p>
        </div>
	);
};

function share() {
    navigator.share({url: window.location.href});
}

export default Blog005;