import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './MobileLibraryItem.scss';
import authorImage from "../../assets/images/logo.png";

const MoblieLibraryItem = ({ items }) => {
	return (
		<>
			{items.map((item) => (
				// <Col  className='item-container'>

				// </Col>

				<div className="item-container">
					<div className='item' onClick={() => open(item.url)}>
						<img
							src={item.image}
							alt={item.name}
							className='item-image'
						/>
						<LinkContainer className='item-names' to="">
							<h2>{item.name}</h2>
						</LinkContainer>
						<LinkContainer className='item-names d-lg-none' to=''>
							<p className='short-text' style={{margin: 0}}>{item.short_txt}</p>
						</LinkContainer>
						<div className='mobile-cta-margin  '>
							{' '}
							<img
								src={authorImage}
								alt={item.author.name}
								className='author-image'
							/>
							<h3 class='author-name'>Ricciwawa</h3>
							<LinkContainer
								to=''
								className=' flex-grow ml-auto item-link d-flex align-items-center'
							>
								<span>
									<span className='cat-name'>{item.hsk}</span>
									{/* <img src={arrow} alt='Arrow Icon' /> */}
								</span>
							</LinkContainer>
						</div>

					</div>
				</div>
			))}
		</>
	);
};


function open(url) {
	window.location.href = url;
}
export default MoblieLibraryItem;
