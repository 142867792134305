import a from "assets/images/BLOG/005.jpg";
import b from "assets/images/BLOG/006.png";
import c from "assets/images/BLOG/007.jpeg";

export const items = [
	{
		name: 'How to be your students’ favourite teacher',
		image: 'library-item-image',
		short_txt: 'Would you like to come see movie with me? #dailyconversation',
		brief: 'Would you like to come see movie with me? #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: '005',
		image: a,
	},
	{
		name: 'How to make great teaching materials',
		short_txt: 'Would you like to come see movie with me? #dailyconversation',
		image: 'library-item-image',
		brief: 'Would you like to come see movie with me? #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: '006',
		image: b,
	},
	{
		name: '3 ways to make your online class shine',
		short_txt: 'Would you like to come see movie with me? #dailyconversation2',
		image: 'library-item-image',
		brief: 'Would you like to come see movie with me? #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: '007',
		image: c,
	},
];
