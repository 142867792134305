import React from 'react';
import BlogConsentPage from '../components/BlogConsent/BlogConsentPage.js';



const BlogConsent = () => {
	return (
		<div>
            <BlogConsentPage />
        </div>
	);
};


export default BlogConsent;