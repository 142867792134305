import React, { useState, useEffect, useRef } from 'react';
import Dropdownicon from 'assets/images/dropdown-icon.svg';
import './Dropdown.scss'

const Dropdown = ({
    active,
    menu,
    onClickMenuItem,
}) => {
    const [showPanel, setShowPanel] = useState(false)
    const listRef = useRef(null);

    const handleClickOutside = (event) => {
        if (listRef.current && event.target instanceof Node && !listRef.current.contains(event.target)) {
            setShowPanel(false);
        }
    }

    const handleClick = () => {
        setShowPanel(!showPanel)
    }

    const handleOnClickItem = (item) => {
        setShowPanel(false)
        if(onClickMenuItem)
            onClickMenuItem(item)
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [])

    return (
        <div className="dropdown-wrapper" ref={listRef}>
            <div className="dropdown-header" onClick={handleClick}>
                <div className='w-100 text-center'>{active.label}</div>
                <img className='dr-icon' src={Dropdownicon} alt='' />
            </div>

            <div className={`dropdown-body ${showPanel && 'open'}`}>
                {menu.map((item) => (
                    <div 
                        key={`menu-${item.id}`} 
                        className="dropdown-item" 
                        onClick={() => handleOnClickItem(item)}
                    >
                        <span className={`dropdown-item-dot ${item == active && 'selected'}`}>
                            •{' '}
                        </span>
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Dropdown;