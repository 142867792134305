import { Route, Routes } from 'react-router-dom';
import 'assets/styles/App.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { Footer, Navigation, ScrollToTop } from './components';
import {
	Blog,
	Contact,
	Home,
	Library,
	Pricing,
	PrivacyPolicy,
	TermsAndConditions,
	BlogConsent,
	Blog001,
	Blog002,
	Blog003,
	Blog004,
	Blog005,
	Blog006,
	Blog007,
} from './pages';

function App() {
	return (
		<div className='App'>
			<Navigation />
			<ScrollToTop>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='library' element={<Library />} />
					<Route path='blog' element={<Blog />} />
					<Route path='blog/consent' element={<BlogConsent />} />
					<Route path='blog/001' element={<Blog001 />} />
					<Route path='blog/002' element={<Blog002 />} />
					<Route path='blog/003' element={<Blog003 />} />
					<Route path='blog/004' element={<Blog004 />} />
					<Route path='blog/005' element={<Blog005 />} />
					<Route path='blog/006' element={<Blog006 />} />
					<Route path='blog/007' element={<Blog007 />} />
					<Route path='contact' element={<Contact />} />
					<Route path='pricing' element={<Pricing />} />
					<Route path='privacy-policy' element={<PrivacyPolicy />} />
					<Route path='terms-and-conditions' element={<TermsAndConditions />} />
				</Routes>
			</ScrollToTop>
			<Footer />
		</div>
	);
}

export default App;
