import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import mobileBg from '../../assets/images/contact-page-ellipse.svg';
import './ContactForm.scss';

function ContactForm() {
	const [t, i18n] = useTranslation('translation');
	return (
		<div className='form-part'>
			<div className='top-section'>
				<div className='section-heading'>{t('contact_page.title')}</div>
				<div className='sub-text'>
					{t('footer_about')}
				</div>
				<div className='sub-text d-none d-lg-block'>
					{t('contact_page.description')}
				</div>
			</div>

			<Form className='c-form'>
				<Form.Group className='form-group' controlId='formName'>
					<Form.Label className='label-name position-relative'>{t('name')}</Form.Label>
					<Form.Control
						className='from-field'
						type='name'
						placeholder={t('enter_name')}
					/>
				</Form.Group>

				<Form.Group className='form-group' controlId='formEmail'>
					<Form.Label className='label-email position-relative'>
						{t('email')}
					</Form.Label>
					<Form.Control
						className='from-field'
						type='email'
						placeholder={t('enter_email')}
					/>
				</Form.Group>
				<Form.Group
					className='form-group'
					controlId='formMessage'
				>
					<Form.Label className='label-message position-relative'>
						{t('drop_message')}
					</Form.Label>
					<Form.Control
						className='from-field'
						as='textarea'
						rows={6}
						placeholder={t('type_text_here')}
					/>
				</Form.Group>
				<div className='d-flex justify-content-center'>
					<button className='form-button' onClick={submit}>
						{t('submit')}
					</button>
				</div>
			</Form>
			<img
				src={mobileBg}
				alt='...'
				className='contact-page-mobile-bg d-xxl-none'
			/>


		</div>
	);
}

function submit() {
	let name = String(document.getElementById("formName").value).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
	let email = String(document.getElementById("formEmail").value).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
	let message = String(document.getElementById("formMessage").value).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
	let xhttp = new XMLHttpRequest;
	xhttp.onreadystatechange = function () {
		if (xhttp.readyState === 4 && xhttp.status === 200) {
			alert('Message sent successfully. Ricciwawa will contact you shortly.');
		};
	};
	xhttp.open('POST', window.location.origin + '/contact_us_form_data?' + "name=" + encodeURIComponent(name) + '&email=' + encodeURIComponent(email) + '&message=' + encodeURIComponent(message));
	xhttp.send();
}

export default ContactForm;
