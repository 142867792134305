import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Navigation } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderCardSmall } from '../../components';
import { items } from '../../pages/blog_data/1';
import './BlogsSlider.scss';

const BlogsSlider = ({ title }) => {
	return (
		<Row className='custom-container-only-left section-wrapper section-wrapper-blog  mx-0 position-relative w-100 d-flex align-items-center blog-slider'>
			<Col cols={12} className='px-0'>
				<h2 className='blog-slider-title'>{title}</h2>
			</Col>
			<Col cols={12} className='position-relative slider-container px-0'>
				<Swiper
					slidesPerView={1}
					spaceBetween={5}
					navigation={{
						nextEl: '.next-blog',
						prevEl: '.prev-blog',
					}}
					breakpoints={{
						370: {
							slidesPerView: 1.5,
							spaceBetween: 30,
						},

						600: {
							slidesPerView: 2,
							spaceBetween: 30,
						},
						768: {
							slidesPerView: 2.5,
							spaceBetween: 30,
						},
						1440: {
							slidesPerView: 3.0,
							spaceBetween: 30,
						},
						1700: {
							slidesPerView: 4.2,
							spaceBetween: 30,
						},
					}}
					modules={[Navigation]}
					className='blog-slider-card'
				>
					{items.map((item) => (
						<SwiperSlide>
							<SliderCardSmall item={item} />
						</SwiperSlide>
					))}
				</Swiper>
				<div className='next-blog'></div>
				<div className='prev-blog'></div>
			</Col>
		</Row>
	);
};

export default BlogsSlider;
