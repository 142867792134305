import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
// import required modules
import { Navigation, Pagination } from 'swiper'; // import required modules
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import circle from '../../assets/images/review-circle.svg';
import ballsBig from '../../assets/images/two-big-balls.svg';
import ballsSmall from '../../assets/images/two-small-balls.svg';
import CommonButton from '../CommonButton/CommonButton';
import './ReviewSlider.scss';
import t1 from "../../assets/images/HOME/t1.JPG";
import t2 from "../../assets/images/HOME/t2.JPG";
import t3 from "../../assets/images/HOME/t3.JPG";
import t4 from "../../assets/images/HOME/t4.JPG";
import t5 from "../../assets/images/HOME/t5.JPG";

const ReviewSlider = () => {
	const [t, i18n] = useTranslation('translation');

	const Reviews = [
		{ id: 1, review: t('review-1'), image: t4, reviewer: t('review-1-name'), location: t('review-1-loc') },
		{ id: 2, review: t('review-2'), image: t5, reviewer: t('review-2-name'), location: t('review-2-loc') },
		{ id: 3, review: t('review-3'), image: t1, reviewer: t('review-3-name'), location: t('review-3-loc') },
		{ id: 4, review: t('review-4'), image: t2, reviewer: t('review-4-name'), location: t('review-4-loc') },
		{ id: 5, review: t('review-5'), image: t3, reviewer: t('review-5-name'), location: t('review-5-loc') },
	]

	return (
		<>
			<section className='custom-container review-slider-container section-wrapper'>
				<Row>
					<Col sm={12}>
						<h1 className='review-section-title'>
							{t('what_says')}
						</h1>
					</Col>
					<Col sm={12} className='slider-section'>
						<Swiper
							slidesPerView={3}
							slidesPerGroup={1}
							centeredSlides={true}
							loop={true}
							loopFillGroupWithBlank={true}
							navigation={true}
							modules={[Pagination, Navigation]}
							breakpoints={{
								300: {
									slidesPerView: 1,
									spaceBetween: 40,
								},

								600: {
									slidesPerView: 2,
									spaceBetween: 30,
								},

								768: {
									slidesPerView: 3,
									spaceBetween: 5,
								},

								992: {
									slidesPerView: 3,
									spaceBetween: 10,
								},
								1200: {
									slidesPerView: 3,
									spaceBetween: 30,
								},
							}}
							className='mySwiper'
						>
							<div className='swiper-buttonprev'>Prev</div>
							<div className='swiper-buttonnext'>Next</div>
							{Reviews.map((_review) => (
							<SwiperSlide key={_review.id}>
								<div className='position-relative slide-style'>
									<div className='slide-content d-flex flex-column justify-content-between'>
										<img src={_review.image} alt='...' className='image' />
										<h2 className='title'>{_review.review}</h2>
										<div className='info-wrapper'>
											<h3 className='name'>{_review.reviewer}</h3>
											<p className='designation'>{_review.location}</p>
										</div>
									</div>
								</div>
							</SwiperSlide>
							))}
						</Swiper>
						{/* </Swiper> */}
					</Col>
					<Col className='d-none d-lg-flex justify-content-center '>
						<CommonButton />
					</Col>
				</Row>
				<img
					id='small-ball'
					className='d-none d-lg-block'
					src={ballsSmall}
					alt='...'
				/>
				<img
					id='big-ball'
					className='d-none d-lg-block'
					src={ballsBig}
					alt='...'
				/>
				<img
					id='big-circle'
					className='d-block d-lg-none'
					src={circle}
					alt='...'
				/>
			</section>
		</>
	);
};

export default ReviewSlider;
