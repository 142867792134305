import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { getItemToLocalStorage } from "functions/helper";
import translation_en from "./assets/langs/en/translations.json";
import translation_ch from "./assets/langs/ch/translations.json";
import translation_chtr from "./assets/langs/ch-tr/translations.json";

let _lang = getItemToLocalStorage('language', 'en')

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: _lang,                              // language to use
  resources: {
    en: {
      translation: translation_en
    },
    ch: {
      translation: translation_ch
    },
	ch_tr: {
		translation: translation_chtr
	}
  },
});

ReactDOM.render(
	<BrowserRouter>
    	<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
