import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { AppProcessSlider, HeroSection, ReviewSlider } from 'components';
import VerticalSlider from '../components/VerticalSlider/VerticalSlider';

const Home = () => {
	const [t, i18n] = useTranslation('translation');

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{t('home')} | Ricciwawa</title>
			</Helmet>
			<HeroSection />
			<VerticalSlider />
			<AppProcessSlider />
			<ReviewSlider />
		</>
	);
};

export default Home;
