export const items = [
    {
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Letter of Consent',
		short_txt: 'How to ask for parent’s permission to download Ricciwawa app on child’s phone (Template)',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: 'https://ricciwawa-web.vercel.app/post/726',
		image: './src/images/author.png',
		hsk: "HSK 1"
	},
]