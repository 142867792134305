export const items = [
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'How’s the weather?',
		short_txt: '天气怎么样？ #hsk1 #dailyconversation',
		author: {
			image: './src/images/logo.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/726',
		image: 'https://cdn2.momjunction.com/wp-content/uploads/2016/01/Steps-To-Fly-A-Kite-With-Your-Kid.jpg',
		hsk: "HSK 1"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Do you like vegetables?',
		short_txt: '你喜欢吃蔬菜吗？ #food #hsk1',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/727',
		image: 'https://cdn.aarp.net/content/dam/aarp/home-and-family/your-home/2021/05/1140-tomatoes-from-garden.web.jpg',
		hsk: "HSK 1"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Baidu',
		short_txt: '#hsk1 #technology',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/721',
		image: 'https://img.caixin.com/2021-03-02/1614677685962822.jpg',
		hsk: "HSK 1"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'My favorite food',
		short_txt: '我最喜欢的食物 #hsk2 #food',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/738',
		image: 'https://envato-shoebox-0.imgix.net/4f2c/6761-78fc-412b-97df-58ba2915d902/IMG_2686-Exposure+0000.jpg?auto=compress%2Cformat&fit=max&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark2.png&markalign=center%2Cmiddle&markalpha=18&w=700&s=2a82673436222ff04ed0ccfaf44c907a',
		hsk: "HSK 2"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'What is love?',
		short_txt: '幸福是什么？ #hsk2 #soupforsoul',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2170',
		image: 'https://img.shoplineapp.com/media/image_clips/5f2ba95f33e9a8002767f2b3/original.png?1596696927',
		hsk: "HSK 2"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Come visit China!',
		short_txt: '来中国玩吧！ #hsk2 #travel',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/733',
		image: 'http://5b0988e595225.cdn.sohucs.com/images/20200106/112eacb1305e4d05ae90cdc8308ee8b5.jpeg',
		hsk: "HSK 2"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'The four seasons',
		short_txt: '季节 #hsk3 #weather #nature',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2177',
		image: 'https://sites.google.com/site/cchkdoie/_/rsrc/1383046737860/ji-jie/main.jpg',
		hsk: "HSK 3"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Didi Taxi',
		short_txt: '滴滴打车 #hsk3 #technology',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2173',
		image: 'https://ichef.bbci.co.uk/news/976/cpsprodpb/2558/production/_119306590_gettyimages-1096270596.jpg',
		hsk: "HSK 3"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Capital city',
		short_txt: '首都 #hsk3 #travel',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2178',
		image: 'https://images.chinatimes.com/newsphoto/2020-01-05/656/20200105000023.jpg',
		hsk: "HSK 3"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'The Olympics',
		short_txt: '奥运 #hsk4 #sports',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/812',
		image: 'https://www.adweek.com/wp-content/uploads/2021/06/olympics-4k-first-time-2021.jpg',
		hsk: "HSK 4"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Book a hotel',
		short_txt: '预订酒店 #hsk4 #travel #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/810',
		image: 'https://n.sinaimg.cn/weibo_pub/transform/158/w550h408/20210703/8ee0-krwipas5446084.png',
		hsk: "HSK 4"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Why you should learn Chinese?',
		short_txt: '为什么要学汉语？ #hsk4 #discussion',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/807',
		image: 'https://kunkwan.my/kunkwan_master/news/news-11-Picture2.jpg',
		hsk: "HSK 4"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Chinese New Year Festival Gala',
		short_txt: '春节联欢晚会 #hsk5 #culture',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2183',
		image: 'http://p1.img.cctvpic.com/photoAlbum/page/performance/img/2019/2/5/1549298757889_678.jpg',
		hsk: "HSK 5"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Guide dog',
		short_txt: '导盲犬 #hsk5 #discussion',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2185',
		image: 'https://img.ltn.com.tw/Upload/news/600/2019/08/18/2888476_1_1.jpg',
		hsk: "HSK 5"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Better late than never',
		short_txt: '亡羊补牢 #hsk4 #idioms',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2182',
		image: 'https://i.ytimg.com/vi/MxibBhKuIe8/maxresdefault.jpg',
		hsk: "HSK 5"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Asian Games',
		short_txt: '亚运会 #culture #hsk6',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2287',
		image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/2006_Asian_Games_athletes_during_opening_ceremony.jpg/771px-2006_Asian_Games_athletes_during_opening_ceremony.jpg',
		hsk: "HSK 6"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'The Forbidden City',
		short_txt: '故宫 #hsk6 #history',
		author: {
			image: './src/images/author.png',
			name: 'Ricciwawa',
		},
		url: 'https://story.ricciwawa.com/post/2288',
		image: 'https://pic5.40017.cn/03/000/dd/48/rB5oQFx9-RWAIE49AAG4uVc17aM381.jpg',
		hsk: "HSK 6"
	},
];
