import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SliderBg from '../../assets/images/background_svg_dot.svg';
import Mobile from '../../assets/images/mobile.svg';
import threedots from '../../assets/images/threedots.svg';
import './AppProcessSlider.scss';
import a1 from "../../assets/images/HOME/a1.png";
import a2 from "../../assets/images/HOME/a2.png";
import a3 from "../../assets/images/HOME/a3.png";
import a4 from "../../assets/images/HOME/a4.png";
import a5 from "../../assets/images/HOME/a5.png";

const AppProcessSlider = () => {
	const [t, i18n] = useTranslation('translation');
	
	const Features = [
		{
			id: 1,
			title: t('dual_lang'),
			image: a1,
			description: t('dual_lang_desc')
		},
		{
			id: 2,
			title: t('assistive_tools'),
			image: a2,
			description: t('assistive_tools_desc')
		},
		{
			id: 3,
			title: t('multi_lang_dict'),
			image: a3,
			description: t('multi_lang_dict_desc')
		},
		{
			id: 4,
			title: t('natural_voice'),
			image: a4,
			description: t('natural_voice_desc')
		},
		{
			id: 5,
			title: t('pdf_output'),
			image: a5,
			description: t('pdf_output_desc')
		}
	]
	return (
		<>
			<div className='app-process-slider position-relative'>
				<Row className='custom-container h-100 d-flex section-wrapper align-items-center position-relative slider-content-container slider-wrap-mobile mx-0 position-relative w-100'>
					<Col className='col-12'>
						<Row>
							<Col className='col-12'>
								<div className='slider-container-title'>
									<h1>{t('features')}</h1>
									<div className='bars'>
										<span></span>
										<span></span>
										<span></span>
									</div>
								</div>
							</Col>
							<Col className='slider col-12 '>
								<img src={threedots} alt='...' className='dot-balls' />
								<Swiper
									spaceBetween={0}
									centeredSlides={true}
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									pagination={{
										clickable: true,
									}}
									navigation={true}
									modules={[Autoplay, Pagination, Navigation]}
									className='mySwiper'
								>
									{Features.map((feature) => (
									<SwiperSlide key={feature.id}>
										<Row className='d-flex justify-content-between align-items-center'>
											<Col className='slide-title col-3 d-none d-xl-block'>
												<h1>
													<span>{feature.title}</span>
												</h1>
											</Col>
											<Col className='flex-grow d-flex justify-content-center col-6 col-xl-5'>
												<img src={feature.image} alt='...' className='slider-mobile' />
											</Col>
											<Col className='col-6 col-xl-4'>
												<div className='slide-title d-block d-xl-none'>
													<h1>
														<span>{feature.title}</span>
													</h1>
												</div>
												<div className='slide-text '>
													<p>
													{feature.description}
													</p>
													<button class='app-slider-button' onClick={toSignIn}>{t('try_free')}</button>
												</div>
											</Col>
										</Row>
									</SwiperSlide>
									))}
								</Swiper>
							</Col>
						</Row>
					</Col>
					{/* <Col className='d-flex justify-content-end px-0 position-absolute slider-svg-container'>
						
					</Col> */}

					<div className='bg-img px-0 d-none d-lg-block'>
						<img src={SliderBg} alt='...' className='slider-bg-image' />
					</div>
				</Row>
			</div>
		</>
	);
};

function toSignIn() {
	window.open('https://story.ricciwawa.com/signin');
}

export default AppProcessSlider;
