import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/006.png";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog006 = () => {
	return (
		<div id='blogRead'>
            <h3>How to make great teaching materials</h3>
            
            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>
            For many teachers, the hardest part of creating materials for their students is getting started. They worry about whether what they will create is good.
            <br /><br />
            The fact is, many great writers did not start out great and you should not expect to create anything fantastic from the start.
            <br /><br />
            If you cannot think of a story, why not take your phone, shoot a video as you walk along then write down what you see. Think of all the vocabulary you could teach. Keep doing this, take more videos and write more.
            <br /><br />
            You WILL get better and as you do, start to focus on individual items as you make your video. For example, a cat sitting by the window. Write down a description of the cat, how the sun falls on its face, the color of its fur, what you think it is looking at. The video of the cat. This – your video and story - already could make for great teaching content.
            <br /><br />
            Or better yet, after you show them your story and video about the cat, ask them to talk about what they see in the video, what they think the cat is doing, or maybe even write their own stories – and add a new level of engagement to the lesson.
            <br /><br /><br /><br /></p>
        </div>
	);
};

function share() {
    navigator.share({url: window.location.href});
}

export default Blog006;