import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/002.jpg";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog002 = () => {
	return (
		<div id='blogRead'>
            <h3>Reimagining Education (1)</h3>
            
            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>
            Covid-19 forced many schools to go from physical to online classes and, given the current continuing sporadic disease outbreaks, it is probably safe to assume online education is here to stay.<br /><br />
            The challenge for schools and instructors with online training is not only finding and using new technology, but also reimaging its education to help students and academic staff.
            <br /><br />
            This reimagining requires educators put considerable effort to increase engagement, retain their attention, get feedback and assess them. But doing so online requires different skills and teaching techniques from physical classes.
            <br /><br />
            This requires, in many cases, making online courses dynamic, interesting and interactive.<br /><br />
            Fortunately for teachers still finding this a daunting challenge, help is on the way. (More to come)
            <br /><br /><br /><br /></p>
        </div>
	);
};

function share() {
    navigator.share({url: window.location.href});
}

export default Blog002;