import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AppleStore from 'assets/images/applestore.svg';
import Logo from 'assets/images/logo.png';
import MobileMenuicon from 'assets/images/mobile-menu-icon.svg';
import PlayStore from 'assets/images/playstore.svg';
import toAppleStore from "functions/toAppleStore";
import toGoogleStore from "functions/toGoogleStore";
import Dropdown from '../Dropdown/Dropdown';
import { getItemToLocalStorage, setItemToLocalStorage } from "functions/helper";
import './Navbar.scss';

const langs = [
	{ id: 'en', label: 'Eng' },
	{ id: 'ch_tr', label: '繁' },
	{ id: 'ch', label: '简' },
];

function Navigation() {
	const [click, setClick] = React.useState(false);
	const [t, i18n] = useTranslation('translation');

	const handleClick = () => setClick(!click);
	const Close = () => setClick(false);


	let _lang = getItemToLocalStorage('language', langs[0].id)
	const [currentLang, setCurrentLang] = useState(langs.filter((v) => v.id === _lang)[0]);

	const handleLangMenuClick = (lang) => {
		setItemToLocalStorage('language', lang.id);
		setCurrentLang(lang)
	};
	
	useEffect(() => {
		i18n.changeLanguage(currentLang.id);
	}, [currentLang]);

	return (
		<div>
			<div className={click ? 'main-container' : ''} onClick={() => Close()} />
			<nav
				className='navbar custom-container'
				onClick={(e) => e.stopPropagation()}
			>
				<div className='nav-container'>
					<NavLink exact to='/' className='nav-logo'>
						<div className='logo-wrap'>
							<img src={Logo} alt='logo' className='site-logo' />
							<div className='site-name'>RicciWawa</div>
						</div>
					</NavLink>
					<ul className={click ? 'nav-menu active' : 'nav-menu'}>
						<li className='nav-item'>
							<NavLink
								exact
								to='/library'
								className='nav-links'
								onClick={click ? handleClick : null}
							>
								{t('library')}
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								exact
								to='/pricing'
								className='nav-links'
								onClick={click ? handleClick : null}
							>
								{t('pricing')}
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								exact
								to='/blog'
								className='nav-links'
								onClick={click ? handleClick : null}
							>
								{t('blog')}
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								exact
								to='/contact'
								className='nav-links'
								onClick={click ? handleClick : null}
							>
								{t('contact')}
							</NavLink>
						</li>
						{/* <li className='nav-item'>
							<a
								href="https://forum.ricciwawa.com/"
								className='nav-links'
							>
								Forum
							</a>
						</li> */}
					</ul>

					<ul className='right-side'>
						<img className='app-icon' onClick={toAppleStore} src={AppleStore} alt='...' />
						<img className='app-icon' onClick={toGoogleStore} src={PlayStore} alt='...' />

						<div className='lang-item desktop-only'>
							<Dropdown 
								active={currentLang}
								menu={langs}
								onClickMenuItem={handleLangMenuClick}
							/>
						</div>
						<button onClick={signInButton} className='signInButton'>{t('sign_in')}</button>
					</ul>

					<div>
						<div className='lang-item mobile-only'>
							<Dropdown 
								active={currentLang}
								menu={langs}
								onClickMenuItem={handleLangMenuClick}
							/>
						</div>
						<div className='nav-icon' onClick={handleClick}>
							<img src={MobileMenuicon} alt='' />
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
}

function signInButton() {
	window.open("https://story.ricciwawa.com/");
}

export default Navigation;