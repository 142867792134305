import React from 'react';
import contactBg from '../../assets/images/contact-page-bg.jpg';
import './ContactInfo.scss';

function ContactInfo() {
	return (
		<div className='contact-info'>
			<div className='page-bg'>
				<img src={contactBg} alt='Contact Bg' />
				<div className='info-c-wrapper'>
					{/* <h3>Contact Us</h3> */}
					<ul className='info-item'>
						<li className='contact-item-name'>
							<a href='/'>
								{/* <img src={PhoneIcon} alt='' /> */}
								<p>+852 5147 5752</p>
							</a>
						</li>
						<li className='contact-item-name'>
							<a href='/'>
								{/* <img src={EmailIcon} alt='' /> */}
								<p>info@ricciwawa.com</p>
							</a>
						</li>
					</ul>
				</div>
			</div>

			{/* <img src={fakeContactBg} alt="" /> */}
		</div>
	);
}

export default ContactInfo;
