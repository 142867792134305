import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './richtext.module.css';

const PrivacyPolicy = () => {
	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Privacy Policy| Ricciwawa</title>
			</Helmet>
			<main className='custom-container section-wrapper'>
				<h1 className={styles.h1}>Privacy Policy</h1>
				<p className={styles.p}>
					Welcome to Makebell (the “Platform”). The Platform is provided and
					controlled by Makebell Ltd., with its registered address at 41 Ha Shan
					Kai Wat, Fanling NT, Hong Kong (“Makebell”, “we” or “us”).
				</p>
				<p className={styles.p}>
					We are committed to protecting and respecting your privacy. This
					policy explains our practices concerning the personal data we collect
					from you, or that you provide to us. If you do not agree with this
					policy, you should not use the Platform.
				</p>
				<p className={styles.p}>
					If you have any questions about how we use your personal data, contact
					us at info@ricciwawa.com
				</p>
				<h1 className={(styles.h1, styles.h1mt)}>SUMMARY</h1>
				<section>
					<h2 className={styles.h2}>
						What information do we collect about you?
					</h2>
					<p className={styles.p}>
						We collect and process information you give us when you create an
						account and upload content to the Platform. This includes technical
						and behavioural information about your use of the Platform. We also
						collect information about you if you download the app and interact
						with the Platform without creating an account.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>
						How will we use the information about you?
					</h2>
					<p className={styles.p}>
						We use your information to provide the Platform to you and to
						improve and administer it. We use your information to, among other
						things, show you suggestions, improve and develop the Platform and
						ensure your safety. Where appropriate, we will also use your
						personal information to serve you targeted advertising and promote
						the Platform.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Who do we share your information with?</h2>
					<p className={styles.p}>
						We share your data with third party service providers who help us to
						deliver the Platform, such as cloud storage providers. We also share
						your information with business partners, other companies in the same
						group as Makebell, content moderation services, measurement
						providers, advertisers, and analytics providers. Where and when
						required by law, we will share your information with law enforcement
						agencies or regulators, and with third parties pursuant to a legally
						binding court order.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>
						How long do we keep hold of your information?
					</h2>
					<p className={styles.p}>
						We retain your information for as long as it is necessary to provide
						you with the service. Where we do not need your information in order
						to provide the service to you, we retain it only as long as we have
						a legitimate business purpose in keeping such data or where we are
						subject to a legal obligation to retain the data. We will also
						retain your data if we believe it is or will be necessary for the
						establishment, exercise or defence of legal claims.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>
						How will we notify you of any changes to this Privacy Policy?
					</h2>
					<p className={styles.p}>
						We will generally notify all users of any material changes to this
						policy through a notice on our Platform. However, you should look at
						this policy regularly to check for any changes. By accessing or
						using the Platform, you acknowledge that you have read this policy
						and that you understand your rights in relation to your personal
						data and how we will collect, use and process it.
					</p>
				</section>

				<section>
					<h2 className={styles.h2}>The types of personal data we use</h2>
					<p className={styles.p}>
						We collect and use the following information about you:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							Your Profile Information. You give us information when you
							register on the Platform, including your username, password, date
							of birth (where applicable), email address and/or telephone
							number, information you disclose in your user profile.
						</li>
						<li className={styles.li}>
							Content and Behavioral Information. We process the content you
							generate and view on the Platform, including preferences you set
							(such as choice of language) photographs, audios and videos you
							upload or create, comments and livestreams you make (“Content”).
							We collect Content through pre-loading at the time of creation,
							import, or upload, regardless of whether you choose to save or
							upload that Content, in order to recommend audio options and
							provide other personalized recommendations. If you apply an effect
							to your Content, we may collect a version of your Content that
							does not include the effect. We may collect information about the
							images and audio that are a part of your Content, such as
							identifying the objects and scenery that appear, the existence and
							location within an image of face and body features and attributes,
							the nature of the audio, and the text of the words spoken in your
							Content. We may collect this information to enable special video
							effects, for content moderation, for demographic classification,
							for content and ad recommendations, and for other
							non-personally-identifying operations. We may access content,
							including text, images, and video, found in your device’s
							clipboard, with your permission. We collect information through
							surveys, challenges and competitions in which you participate. We
							also collect information regarding your use of the Platform, e.g.,
							how you engage with the Platform, including how you interact with
							content we show to you, the ads you view, videos you watch and
							problems encountered, the content you like, the content you save
							to ‘My Favourites’, the users you follow and how you engage with
							mutual followers. We also infer your preferences, including your
							interests, gender and age for the purpose of personalising
							content. We process information about your followers, the likes
							you receive and responses to content you upload, for the purposes
							of promoting your content to other users and exploring whether
							your profile presents further opportunities for collaboration.
							Where appropriate, we will also use this information for the
							purpose of serving personalised advertising and to tell you about
							new services and opportunities.
						</li>

						<li className={styles.li}>
							Information from Third Parties. You may choose to share certain
							data with us from third parties or through your use of the
							Platform, we may collect such third party data automatically. We
							have set out further detail on the information we receive from
							third parties below:
						</li>
					</ul>
				</section>
				<section>
					<h2 className={styles.h2}>
						Advertisers, Advertising Networks, and Analytic Providers
					</h2>
					<p className={styles.p}>
						We use the information collected from you, and your interaction with
						the Platform and other third-party sites and Apps, to infer your
						likely interests to provide you with more relevant advertising. This
						information tells us about websites you've visited, apps you've
						downloaded and purchases you have made so that we can predict what
						else might interest you in the future and assess how effective the
						advertising on our Platform is. We collect this information by the
						use of Cookies and similar technologies on our Platform and from
						similar information received from third parties who advertise on our
						Platform and whose sites/Apps you visit.
					</p>
				</section>

				<section>
					<h2 className={styles.h2}>
						Technical Information we collect about you.
					</h2>

					<p className={styles.p}>
						We collect certain information about the device you use to access
						the Platform, such as your IP address, user agent, mobile carrier,
						time zone settings, identifiers for advertising purposes, model of
						your device, the device system, network type, device IDs, your
						screen resolution and operating system, app and file names and
						types, keystroke patterns or rhythms, battery state, audio settings
						and connected audio devices. Where you log-in from multiple devices,
						we will be able to use your profile information to identify your
						activity across devices. We may also associate you with information
						collected from devices other than those you use to log-in to the
						Platform.
					</p>
					<p className={styles.p}>Other information we collect include:</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							Location. We collect information about your approximate location,
							including location information based on your SIM card and/or IP
							address. With your permission, we may also collect precise
							location data (such as GPS).
						</li>

						<li className={styles.li}>
							Proof of your identity, age or affiliation. We sometimes ask you
							to provide proof of identity, age or affiliation for access to the
							Services or for reasons of Security and Safety.
						</li>
					</ul>
				</section>

				<section>
					<h2 className={styles.h2}>Cookies</h2>
					<p className={styles.p}>
						We and our vendors and service providers use cookies and other
						similar technologies (e.g., web beacons, flash cookies, etc.)
						(“Cookies”) to automatically collect information, measure and
						analyze which web pages you click on and how you use the Platform,
						enhance your experience using the Platform, improve our services,
						and provide you with targeted advertising on the Platform and
						elsewhere across your devices. Cookies enable the Platform to
						provide certain features and functionality. Web beacons are very
						small images or small pieces of data embedded in images, also known
						as “pixel tags” or “clear GIFs,” that can recognize Cookies, the
						time and date a page is viewed, a description of the page where the
						pixel tag is placed, and similar information from your computer or
						device. By using the Platform, you consent to our use of Cookies.
					</p>
					<p className={styles.p}>
						Additionally, we may allow our business partners, advertising
						networks, and other advertising vendors and service providers
						(including analytics vendors and service providers) to collect
						information about your online activities through Cookies. We link
						your contact or subscriber information with your activity on our
						Platform across your devices, using your email or other log-in or
						device information. These third parties may use this information to
						display advertisements on our Platform and elsewhere online tailored
						to your interests, preferences, and characteristics. We are not
						responsible for the privacy practices of these third parties, and
						the information practices of these third parties are not covered by
						this Privacy Policy.
					</p>
					<p className={styles.p}>
						You may be able to refuse or disable Cookies by adjusting your
						browser settings. Because each browser is different, please consult
						the instructions provided by your browser. Please note that you may
						need to take additional steps to refuse or disable certain types of
						Cookies. For example, due to differences in how browsers and mobile
						apps function, you may need to take different steps to opt out of
						Cookies used for targeted advertising in a browser and to opt out of
						targeted advertising for a mobile application, which you may control
						through your device settings or mobile app permissions. In addition,
						your opt-out selection is specific to the particular browser or
						device that you are using when you opt out, so you may need to
						opt-out separately for each of browser or device. If you choose to
						refuse, disable, or delete Cookies, some of the functionality of the
						Platform may no longer be available to you.
					</p>
				</section>

				<section>
					<h2 className={styles.h2}>How we use your personal data</h2>
					<p className={styles.p}>
						We will use the information we collect about you in the following
						ways:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							notify you about changes to our service;
						</li>
						<li className={styles.li}>provide you with user support;</li>
						<li className={styles.li}>
							personalise the content you receive and provide you with tailored
							content that will be of interest to you;
						</li>
						<li className={styles.li}>
							enable you to share Content and interact with other users;
						</li>
						<li className={styles.li}>
							enable our messenger service to function if you choose to use this
							function;
						</li>
						<li className={styles.li}>
							enable you to participate in the virtual items program;
						</li>
						<li className={styles.li}>communicate with you;</li>
						<li className={styles.li}>
							to help us detect and combat abuse, harmful activity, fraud, spam,
							and illegal activity on the Platform;
						</li>
						<li className={styles.li}>
							ensure your safety and security including reviewing Content,
							messages and associated metadata for breach of our Community
							Guidelines and other inappropriate content;
						</li>
						<li className={styles.li}>
							ensure content is presented in the most effective manner for you
							and your device;
						</li>
						<li className={styles.li}>
							improve, promote and develop the Platform and promote popular
							topics, hashtags and campaigns on the Platform;
						</li>
						<li className={styles.li}>
							carry out data analysis and test the Platform to ensure its
							stability and security;
						</li>
						<li className={styles.li}>
							allow you to participate in interactive features of the Platform;
						</li>
						<li className={styles.li}>
							to enable you to socialise on the Platform, for example, by
							allowing other users to identify you via the "Find other friends"
							function or through their phone contacts;{' '}
						</li>
						<li className={styles.li}>
							to ensure that you are old enough to use our Platform (as required
							by law).
						</li>
						<li className={styles.li}>
							provide you with personalised advertising;
						</li>
						<li className={styles.li}>
							provide you with location-based services (where those services are
							available in your jurisdiction);
						</li>
						<li className={styles.li}>to inform our algorithms</li>
						<li className={styles.li}>
							enforce our terms, conditions and policies;
						</li>
						<li className={styles.li}>
							administer the Platform including troubleshooting; and
						</li>
						<li className={styles.li}>
							to facilitate sales, promotion, and purchase of goods and services
							and to provide user support
						</li>
					</ul>
				</section>

				<section>
					<h2 className={styles.h2}>How we share your personal data</h2>
					<p className={styles.p}>
						We share your data with the following selected third parties:
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Payment Providers</h2>
					<p className={styles.p}>
						If you choose to conduct payment related transactions, we will share
						data with the relevant payment provider to facilitate this
						transaction.{' '}
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Service Providers </h2>
					<p className={styles.p}>
						We provide information and content to service providers who support
						our business, such as cloud service providers and providers of
						content moderation services to ensure that the Platform is a safe
						and enjoyable place and service providers that assist us in
						marketing the Platform.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Analytics</h2>
					<p className={styles.p}>
						We use analytics to help us in the optimisation and improvement of
						the Platform.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Advertisers and Advertising Networks</h2>
					<p className={styles.p}>
						We may share information with advertisers and third-party
						measurement companies to show how many and which users of the
						Platform have viewed or clicked on an advertisement. We share your
						device ID with measurement companies so that we can link your
						activity on the Platform with your activity on other websites; we
						then use this information to show you adverts which may be of
						interest to you.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Our Corporate Group</h2>
					<p className={styles.p}>
						We may also share your information with other members, subsidiaries,
						or affiliates of our corporate group, to provide the Platform
						including improving and optimising the Platform, preventing illegal
						use and supporting users.
					</p>
				</section>

				<section>
					<h2 className={styles.h2}>Sale or Merger</h2>
					<p className={styles.p}>
						We will also disclose your information to third parties:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							In the event that we sell or buy any business or assets (whether a
							result of liquidation, bankruptcy or otherwise), in which case we
							will disclose your data to the prospective seller or buyer of such
							business or assets; or
						</li>

						<li className={styles.li}>
							If we sell, buy, merge, are acquired by, or partner with other
							companies or businesses, or sell some or all of our assets. In
							such transactions, user information may be among the transferred
							assets.
						</li>
					</ul>
				</section>

				<section>
					<h2 className={styles.h2}>Where we store your personal data</h2>
					<p className={styles.p}>
						The personal data we collect from you may be stored on a server
						located outside of the country where you live. We maintain major
						servers around the world to bring you our services globally and
						continuously.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Your Choices</h2>
					<p className={styles.p}>
						You can access and edit most of your profile information by signing
						into the Services. You can delete the Content you uploaded. Should
						you choose to do so, you may delete your entire account in Settings.
						You may also be afforded rights in your country under applicable
						laws such as the ability to access your data, delete your data, and
						potentially others. If you have any questions on how to use those
						tools, or want to know about any rights you may have in the country
						where you live and want to exercise them, please contact us at:
						info@ricciwawa.com
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>The security of your personal data</h2>
					<p className={styles.p}>
						We take steps to ensure that your information is treated securely
						and in accordance with this policy. Unfortunately, the transmission
						of information via the internet is not completely secure. Although
						we will protect your personal data, for example, by encryption, we
						cannot guarantee the security of your information transmitted via
						the Platform; any transmission is at your own risk.
					</p>
					<p className={styles.p}>
						We have appropriate technical and organizational measures to ensure
						a level of security appropriate to the risk of varying likelihood
						and severity for the rights and freedoms of you and other users. We
						maintain these technical and organizational measures and will amend
						them from time to time to improve the overall security of our
						systems.
					</p>
					<p className={styles.p}>
						We will, from time to time, include links to and from the websites
						of our partner networks, advertisers and affiliates. If you follow a
						link to any of these websites, please note that these websites have
						their own privacy policies and that we do not accept any
						responsibility or liability for these policies. Please check these
						policies before you submit any information to these websites.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>How long we keep your personal data</h2>
					<p className={styles.p}>
						We retain your information for as long as it is necessary to provide
						you with the service. Where we do not need your information in order
						to provide the service to you, we retain it only for so long as we
						have a legitimate business purpose in keeping such data. However,
						there are occasions where we are likely to keep this data for longer
						in accordance with our legal obligations or where it is necessary
						for the establishment, exercise or defence of legal claims.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Information relating to children</h2>
					<p className={styles.p}>
						The Platform and Services are not directed at children under the age
						of 13. In certain cases this age may be higher due to local
						regulatory requirements, please see your local privacy policy for
						more information. If you believe that we have personal data about or
						collected from a child under the relevant age, contact us at:
						info@ricciwawa.com
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Complaints</h2>
					<p className={styles.p}>
						In the event that you wish to make a complaint about how we process
						your personal data, contact us immediately at: info@ricciwawa.com.
						We will endeavour to deal with your request as soon as possible.
						This is without prejudice to your right to launch a claim with the
						relevant data protection authority.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Changes</h2>
					<p className={styles.p}>
						We may update this Privacy Policy from time to time and will provide
						notice required by applicable law. Your continued access to or use
						of the Platform after the date of the updated policy constitutes
						your acceptance of the updated policy. If you do not agree to the
						updated policy, you must stop accessing or using the Platform.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Contact</h2>
					<p className={styles.p}>
						If you have questions, comments, or requests regarding this policy,
						contact us at: info@ricciwawa.com
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Creator Privacy Promise</h2>
					<p className={styles.p}>
						Makebell empowers Creators to earn funds from Subscribers, these
						additional terms apply to Creators.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Creator Agreement</h2>
					<p className={styles.p}>
						To facilitate this direct connection Makebell may provide the
						personal data of Subscribers (“Subscriber Data”) to Creators.
						Creators then process Subscriber Data in order to provide
						Subscribers any and all products or services as part of that
						Creator’s membership business on Makebell (collectively known as
						“Membership Services”). Makebell requires all Creators to agree to
						our Privacy Policy, including this Creator Privacy Promise,to ensure
						that Creators respect the privacy rights of Subscribers when
						processing Subscriber Data.
					</p>
					<p className={styles.p}>
						This Privacy Promise is between Makebell and Creators, taking effect
						from the moment a Makebell account is created and applies
						exclusively to the Subscriber Data collected by Makebell and
						provided to Creators for the purpose of running a membership
						business with Makebell.
					</p>
					<p className={styles.p}>
						This Privacy Promise will outline certain requirements for Creators
						to process Subscriber Data during and beyond their relationship with
						Makebell.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>Definitions</h2>
					<ul className={styles.ul}>
						<li className={styles.li}>
							"Data Protection Legislation" means all applicable laws relating
							to privacy and the processing of personal data that may exist in
							any relevant jurisdiction, including, where applicable, the
							guidance and codes of practice issued by the supervisory
							authorities. Data Protection Legislation includes, but is not
							limited to, Hong Kong\u2019s Personal Data (Privacy) Ordinance
							(Cap. 486), European Directives 95/46/EC and 2002/58/EC (as
							amended by Directive 2009/136/EC) and any legislation and/or
							regulation implementing or made pursuant to them, or which amends,
							replaces, re-enacts or consolidates any of them, including the
							General Data Protection Regulation (Regulation (EU) 2016/279).
						</li>
						<li className={styles.li}>
							"Good Industry Practice" means exercising the same skill,
							expertise and judgement and using facilities and resources of a
							similar quality as would be expected from a person who:(a) is
							skilled and experienced in providing the services in question,
							seeking in good faith to comply with his contractual obligations
							and seeking to avoid liability arising under any duty of care that
							might reasonably apply; (b) takes all proper and reasonable care
							and is diligent in performing his obligations; and (c) complies
							with the Data Protection Legislation.
						</li>
						<li className={styles.li}>
							The terms "data controller", "data processor", “sub processor”,
							"data subject", "personal data", "processing", and "appropriate
							technical and organizational measures" shall be interpreted in
							accordance with applicable Data Protection Legislation, in the
							relevant jurisdiction.
						</li>
					</ul>
				</section>

				<section>
					<h2 className={styles.h2}>Scope</h2>
					<p className={styles.p}>
						The parties agree that Makebell is a data controller and that
						Creator is a data processor in relation to Subscriber Data that
						Creator processes in the course of providing Membership Services.
						The subject matter of the data processing, the types of personal
						data processed, and the categories of data subjects will be defined
						by, and/or limited to, those necessary to carry out the Membership
						Services. The processing to which this Privacy Promise applies will
						be carried out by Creator upon leaving the Services or Platform. The
						subject matter, duration, nature, and purpose of the processing of
						the personal data as well as the type of personal data and
						categories of data subjects covered by this Privacy Promise are as
						follows:
					</p>
					<ol className={styles.ol}>
						<li className={styles.li}>
							The subject matter of the data processing is Subscriber Data
						</li>
						<li className={styles.li}>
							The duration of the processing is for as long as Creator holds
							Subscriber Data.
						</li>
						<li className={styles.li}>
							The nature and purpose of the processing under this Privacy
							Promise is limited to a Creator’s fulfilment of the Creator’s
							obligations to the Subscriber.
						</li>
						<li className={styles.li}>
							The type of personal data covered by this agreement is contact
							information, including but not limited to username, email address,
							shipping address and transaction amounts.
						</li>
					</ol>
				</section>

				<section>
					<h2 className={styles.h2}>Data Protection</h2>
					<p className={styles.p}>
						Creator shall adhere to the following requirements:
					</p>
					<ol className={styles.ol}>
						<li className={styles.li}>
							Processing as Instructed. Creators will process Subscriber Data
							only in accordance with the Terms and this Privacy Policy and only
							in compliance with Data Protection Legislation. The nature and
							purpose of the processing shall be limited to that necessary to
							carry out such instructions, and not for Creator's own purposes,
							or for any other purpose except as required by law. If Creator is
							required by law to process the personal data for any other
							purpose, Creator will inform Subscriber of such requirement prior
							to the processing unless prohibited by law from doing so.
						</li>
						<li className={styles.li}>
							Extent of Processing. Creator will process the personal data only
							to the extent, and in such manner, as is necessary for the
							provision of Membership Services.
						</li>
						<li className={styles.li}>
							Appropriate Technical and Organizational Measures. Creator will
							implement and maintain appropriate technical and organizational
							measures designed to protect the personal data against
							unauthorized or unlawful processing and against accidental loss,
							destruction, damage, theft, alteration or disclosure. The measures
							shall be appropriate to the harm which might result from any
							unauthorized or unlawful processing, accidental loss, destruction,
							damage or theft of the personal data and having regard to the
							nature of the personal data which is to be protected and as a
							minimum shall be in accordance with the Data Protection
							Legislation and Good Industry Practice.
						</li>
						<li className={styles.li}>
							Transfer to Third Parties. Creator will not give access to or
							transfer any personal data to any third party (including any
							affiliates, group companies or subcontractors) without the prior
							consent of Makebell. Creator must also ensure the reliability and
							competence of such third parties, its employees or agents who may
							have access to the personal data processed in the provision of
							Membership Services, and must include in any contract with such
							third party provisions protecting Subscriber which are equivalent
							to those in this Privacy Promise and the Terms of Service and as
							are required by applicable Data Protection Legislation.
						</li>
						<li className={styles.li}>
							Reliability and Competence of Creator Personnel. Creator will take
							reasonable steps to ensure the reliability and competence of any
							Creator personnel who have access to Subscriber Data. Creator will
							ensure that all Creator personnel required to access the personal
							data are informed of the confidential nature of the personal data
							and comply with the obligations set out in this Privacy Promise.
						</li>
						<li className={styles.li}>
							Acknowledgement of Data Protection Legislation and Assistance.
							Creator will take all reasonable steps to assist Makebell in
							complying with applicable Data Protection Legislation. For
							example, Creator will promptly inform Makebell in writing if it
							receives: (i) a request from a data subject concerning any
							personal data; or (ii) a complaint, communication, or request
							relating to Subscriber’s obligations under Data Protection
							Legislation.
						</li>
						<li className={styles.li}>
							Destruction or Return of Property Upon Membership Services
							Completion. Creator will not retain any of the personal data for
							longer than is necessary to provide Membership Services. At the
							end of Membership Services, or upon Subscriber's request, Creator
							will securely destroy or return (at Subscriber’s election) the
							personal data to Subscriber.
						</li>
						<li className={styles.li}>
							Loss or Security Breach. If Creator becomes aware of any
							accidental, unauthorized or unlawful destruction, loss,
							alteration, or disclosure of, or access to Subscriber Data
							processed by Creator in the course of providing Membership
							Services, it will do the following:
							<ol className={styles.ol}>
								<li className={styles.li}>
									Provide notice to Makebell. Creator shall promptly and without
									undue delay notify Makebell and provide Makebell with: a
									detailed description of the Loss or Security Breach; the type
									of data that was the subject of the Loss or Security Breach;
									the identity of each affected person if known, and the steps
									Creator has taken or will take in order to mitigate and
									remediate such Security Breach, in each case as promptly as
									such information can be collected or otherwise becomes
									available (as well as periodic updates to this information and
									any other information Makebell may reasonably request relating
									to the Loss or Security Breach); and
								</li>
								<li className={styles.li}>
									Investigate the Matter promptly. Creator shall promptly take
									action, at its own expense, to investigate the Loss or
									Security Breach and to identify, prevent and mitigate the
									effects of the Loss or Security Breach and to carry out
									appropriate recovery actions to remedy the Loss or Security
									Breach.
								</li>
							</ol>
						</li>
						<li className={styles.li}>
							Compliance with Data Protection Legislation. Creator shall comply
							at all times with and assist Makebell in complying with its
							applicable obligations under Data Protection Legislation. Creator
							shall provide reasonable information requested by Makebell to
							demonstrate compliance with the obligations set out in this
							Privacy Promise. The Creator will notify Makebell immediately if,
							in Creator's opinion, an instruction for the processing of
							personal data given by Makebell violates any country’s data
							privacy legislation.
						</li>
					</ol>
				</section>
			</main>
		</>
	);
};

export default PrivacyPolicy;
