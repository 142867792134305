import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { BlogsSlider, PostCardBig, RelatedBlogSlider } from '../components';
import BlogLatestSlider from '../components/BlogLatestSlider/BlogLatestSlider';
import { items } from './blog_data/latest';

const Blog = () => {
	const [t, i18n] = useTranslation('translation');
	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{t('blog')} | Ricciwawa</title>
			</Helmet>
			<section className='custom-container d-lg-none section-wrapper library-section-container'>
				{/* Content for mobile */}
				<Row className='d-lg-none'>
					<Col sm={12}>
						<h1 className='library-top-slider-title'>{t('latest')}</h1>
					</Col>
					<Col sm={12} >
						<Swiper
							slidesPerView={1}
							spaceBetween={5}
							pagination={true}
						
							breakpoints={{
								380: {
									slidesPerView: 1,
								},

								600: {
									slidesPerView: 2,
									spaceBetween: 30,
								},
								1000: {
									slidesPerView: 2.5,
									spaceBetween: 30,
								},
								1440: {
									slidesPerView: 3.4,
									spaceBetween: 30,
								},
								1700: {
									slidesPerView: 4.2,
									spaceBetween: 30,
								},
							}}
							modules={[Navigation, Pagination]}
							className='relatedBlogSlider'
						>
							{items.map((item) => (
								<SwiperSlide>
									<BlogLatestSlider item={item} />
								</SwiperSlide>
							))}
						</Swiper>
				
					</Col>
				</Row>
			</section>
			<div className='d-none d-lg-block'>
				<PostCardBig />
			</div>
			<BlogsSlider title={t('most_blogs')} />
			<RelatedBlogSlider title={t('for_you')} />
		</>
	);
};

export default Blog;
