import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Col, Row } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import AppleStoreButton from '../../assets/images/apple-store-button.svg';
import post from '../../assets/images/create-post.svg';
import edit from '../../assets/images/edit.svg';
import EmailIcon from '../../assets/images/email-icon.svg';
import GooglePlayButton from '../../assets/images/google-play-button.svg';
import InstagramIcon from '../../assets/images/facebook-icon.svg';
import Logo from '../../assets/images/logo.png';
import PhoneIcon from '../../assets/images/phone-icon.png';
import process from '../../assets/images/process.svg';
import CommonButton from '../CommonButton/CommonButton.js';
import './Footer.scss';

const FooterTop = () => {
	const [t, i18n] = useTranslation('translation');
	let location = useLocation()
	if (location.pathname !== '/contact') {
		return <div className='footer-top d-none d-lg-block'>
			<h3 className='footer-section-title'>{t('where_to_start')}</h3>
			<p className='footer-section-text'>
				{t('where_to_start_desc')}
			</p>
			<CommonButton></CommonButton>
			<img src={post} id='post-image' alt='...' />
			<img src={process} alt='...' id='process-image' />
			<img src={edit} alt='...' id='edit-image' />
		</div>;
	} else {
		return null
	}



}

const Footer = () => {
	const [t, i18n] = useTranslation('translation');
	let location = useLocation()
	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 768px)").matches
	)

	useEffect(() => {
		window
			.matchMedia("(min-width: 1450px)")
			.addEventListener('change', e => setMatches(e.matches));
	}, []);

	return (
		<div className='footer' style={location.pathname === '/contact' && matches ? { paddingTop: '392px' } : {}}>
			<div className='custom-container'>
				<FooterTop></FooterTop>
				<div className='footer-top d-lg-none'>
					<h3 className='footer-section-title2'>{t('try_yourself')}</h3>
					<p className='footer-section-text2'>
						{t('try_yourself_desc')}
					</p>
					<div className='d-flex justify-content-center'>
						<a className='footer-button' href='https://play.google.com/store/apps/details?id=com.makebell.ricciwawa'>
							<img
								className='footer-mobile-app-image'
								src={GooglePlayButton}
								alt=''
							/>
						</a>
						<a className='footer-button' href='https://apps.apple.com/in/app/ricciwawa/id1579274573'>
							<img
								className='footer-mobile-app-image'
								src={AppleStoreButton}
								alt=''
							/>
						</a>
					</div>
				</div>
				<div className='footer-bottom'>
					<Row className='d-flex justify-content-between'>
						<Col className='col-5 d-none d-lg-block'>
							<div className='footer-about'>
								<div className='d-flex align-items-center '>
									<img src={Logo} alt='logo' className='site-logo' />
									<h1 className='footer-logo-name'>RicciWawa</h1>
								</div>
								<p className='footer-text'>
									{t('footer_about')}
								</p>
								<p className='bold-text'>
									<strong>
										{t('suggestions')}
										{/* Got any suggestions? We’d love to hear<br />from you! */}
									</strong>
								</p>
							</div>
						</Col>

						<Col className='col-lg-7 col-12'>
							<div className='row-height w-100'>
								<Row className='custom-row-width w-100 mx-auto'>
									<Col className='col-lg-4 col-6'>
										<div className='footer-items'>
											<h3>{t('contact_us')}</h3>
											<ul className='info-item'>
												<li className='item-name'>
													<a href='https://www.facebook.com/Ricciwawa/'>
														<img src={InstagramIcon} alt='' />
														<p>ricciwawa</p>
													</a>
												</li>
												<li className='item-name'>
													<a href='tel:+852 5147 5752'>
														<img src={PhoneIcon} alt='' />
														<p>+852 5147 5752</p>
													</a>
												</li>
												<li className='item-name'>
													<a href='mailto:info@ricciwawa.com'>
														<img src={EmailIcon} alt='' />
														<p>info@ricciwawa.com</p>
													</a>
												</li>
											</ul>
										</div>
									</Col>
									<Col className='col-lg-4 col-6 '>
										<div className='footer-items'>
											<h3>{t('more')}</h3>
											<ul className='info-item'>
												<li className='item-name'>
													<NavLink exact to='/privacy-policy'>
														{t('privacy_policy')}
													</NavLink>
												</li>
												<li className='item-name'>
													<NavLink exact to='/terms-and-conditions'>
														{t('terms_cond')}
													</NavLink>
												</li>
											</ul>
										</div>
									</Col>
									<Col className='col-4 d-none d-lg-block'>
										<a className='footer-button' target='_blank' href='https://play.google.com/store/apps/details?id=com.makebell.ricciwawa'>
											<img src={GooglePlayButton} alt='' />
										</a>
										<a className='footer-button' target='_blank' href='https://apps.apple.com/in/app/ricciwawa/id1579274573'>
											<img src={AppleStoreButton} alt='' />
										</a>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default Footer;
