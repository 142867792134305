import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from "react-i18next";
import arrow from '../../assets/images/rightarrow.svg';
import './SliderCardSmall.scss';

const SliderCardSmall = ({ item }) => {
	const [t, i18n] = useTranslation('translation');
	return (
		<div class='w-100 card-content-container'>
			<LinkContainer
				to={'/blog/' + item.url}
				className='flex-grow ml-auto item-link d-flex align-items-center  justify-content-end'
			>
			<img
				src={item.image}
				className='slider-image'
				alt='...'
			/>
			</LinkContainer>
			<LinkContainer className='small-card-title' to=''>
				<h2>{item.name}</h2>
			</LinkContainer>

			<LinkContainer
				to={'/blog/' + item.url}
				className='flex-grow ml-auto item-link d-flex align-items-center  justify-content-end'
			>
				<span>
					<span>{t('read')}</span>
					<img src={arrow} alt='..' />
				</span>
			</LinkContainer>
		</div>
	);
};

export default SliderCardSmall;
