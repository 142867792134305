import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './BlogItemSlider.scss';
import consentImg from '../../assets/images/BLOG/consent.jpg';

const BlogLatestSlider = ({ item }) => {
	return (
		<>
			<LinkContainer
				to='/blog/consent'
				className='flex-grow w-100 ml-auto item-link d-flex align-items-center'
			>
				<div className='item-container-blog-mobile'>
					<div className='ls-item w-100'>
						<img
							src={consentImg}
							alt={item.name}
							className='item-image'
						/>
						<LinkContainer className='item-names' to='/'>
							<h2>{item.name}</h2>
						</LinkContainer>
						
					</div>
				</div>
			</LinkContainer>
		</>
	);
};

export default BlogLatestSlider;
