import a from "assets/images/BLOG/002.jpg";
import b from "assets/images/BLOG/003.jpg";
import c from "assets/images/BLOG/004.jpg";

export const items = [
    {
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Reimagining Education (1)',
		short_txt: '天气怎么样？ #hsk1 #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: '002',
		image: a,
		hsk: "HSK 1"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Reimagining Education (2)',
		short_txt: '天气怎么样？ #hsk1 #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: '003',
		image: b,
		hsk: "HSK 1"
	},
	{
		//name: '你想跟我看电影吗？',
		image: 'library-item-image',
		name: 'Why Chinese is so hard',
		short_txt: '天气怎么样？ #hsk1 #dailyconversation',
		author: {
			image: './src/images/author.png',
			name: 'Ron Yu',
		},
		url: '004',
		image: c,
		hsk: "HSK 1"
	},
]