import React from 'react';
import { useTranslation } from "react-i18next";
import "./pricing_page.css";
import toStore from "../functions/toStore";

const Pricing = () => {
    const [t, i18n] = useTranslation('translation');

	return (
		<div id="pricing-page">
            <h3>{t('price_page.title')}</h3>
            <div className='price-item'>
                <p>{t('price_page.basic')}</p>
                <h1>{t('price_page.free')}</h1>
                <p className="light">&nbsp;</p>
                <button onClick={toStore}>{t('price_page.try_now')}</button>
                <hr />
                <ul>
                    <li><span>{t('price_page.basic-features.1')}</span></li>
                    <li><span>{t('price_page.basic-features.2')}</span></li>
                    <li><span>{t('price_page.basic-features.3')}</span></li>
                    <li><span>{t('price_page.basic-features.4')}</span></li>
                    <li><span>{t('price_page.basic-features.5')}</span></li>
                </ul>
            </div>
            <div className='price-item'>
                <p>{t('price_page.pro')}</p>
                <h1>$12.99/<small>{t('price_page.month')}</small></h1>
                <p className="light">{t('price_page.annual')}</p>
                <button onClick={toStore}>{t('price_page.try_now')}</button>
                <hr />
                <ul>
                    <li><span>{t('price_page.pro-features.1')}</span></li>
                    <li><span>{t('price_page.pro-features.2')}</span></li>
                    <li><span>{t('price_page.pro-features.3')}</span></li>
                    <li><span>{t('price_page.pro-features.4')}</span></li>
                </ul>
            </div>
            <div className='price-item'>
                <p>{t('price_page.enterprise')}</p>
                <h1>$30/<small>{t('price_page.month')}</small></h1>
                <p className="light">{t('price_page.annual')}</p>
                <button onClick={toStore}>{t('price_page.try_now')}</button>
                <hr />
                <ul>
                    <li><span>{t('price_page.ent-features.1')}</span></li>
                    <li><span>{t('price_page.ent-features.2')}</span></li>
                    <li><span>{t('price_page.ent-features.3')}</span></li>
                    <li><span>{t('price_page.ent-features.4')}</span></li>
                    <li><span>{t('price_page.ent-features.5')}</span></li>
                </ul>
            </div>
        </div>
	);
};

export default Pricing;