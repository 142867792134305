import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/007.jpeg";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog007 = () => {
	return (
		<div id='blogRead'>
            <h3>3 ways to make your online class shine</h3>
            
            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>
            When I taught primary school, the most important lesson I learned was from an older, experienced educator who taught me that making sure students are not distracted is the first thing you need to do – after all you cannot teach a class when students are running around.
            <br /><br />
            Ensuring your students are not distracted is much harder in today’s online world but it can be done if the content is good, if the words are presented in such a way that students can see how they combine – their elements – and hear the pronunciations.
            <br /><br />
            And if there are online activities – games, communities, quizzes – to ramp up engagement, so much the better.
            <br /><br /><br /><br /></p>
        </div>
	);
};

function share() {
    navigator.share({url: window.location.href});
}

export default Blog007;