import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { LibraryItem, MobileLibraryItem } from '../components';
import { items } from '../data';
import toStore from "../functions/toStore.js";

const Library = () => {
	const [t, i18n] = useTranslation('translation');

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{t('library')} | Ricciwawa</title>
			</Helmet>
			<section>
				<section className='custom-container section-wrapper library-section-container'>
					<Row>
						<Col
							sm={12}
							className=' align-items-center flex-grow d-none d-md-flex'
						>
							<div>
								<h1 className='section-title'>
									{t('select_category')}
								</h1>
							</div>
						</Col>{' '}
					</Row>
					{/* Content for desktop  */}
					<Row className='library-content  align-items-stretch d-none d-md-flex'>
						<LibraryItem showshorttext={true} showauthor={true} items={items} />
					</Row>

					{/* Content for mobile */}
					<Row className='d-md-none'>
						<Col sm={12}>
							<h1 className='library-top-slider-title'>{t('highlight')}</h1>
						</Col>
						<Col sm={12}>
							{/* <Swiper slidesPerView={1}
								spaceBetween={5}
								pagination={true}
								breakpoints={{
									380: {
										slidesPerView: 1,
									},

									600: {
										slidesPerView: 2,
										spaceBetween: 30,
									},
									1000: {
										slidesPerView: 2.5,
										spaceBetween: 30,
									},
									1440: {
										slidesPerView: 3.4,
										spaceBetween: 30,
									},
									1700: {
										slidesPerView: 4.2,
										spaceBetween: 30,
									},
								}}
								modules={[Navigation, Pagination]}
								className='relatedBlogSlider'
							>
								{items.map((item) => (
									<SwiperSlide> */}
							<LibraryItem items={items.slice(0, 1)} showtag={true} />
							{/* </SwiperSlide>
								))}
							</Swiper> */}
						</Col>
					</Row>
				</section>
				<section>
					{/* <div className='d-lg-none'> <BlogsSlider title='Videos' />

					</div> */}
					<div className='d-md-none mobile-layout-card'>
						<div className=' align-items-center flex-grow d-md-none mobile-selection'>
							<div>
								<h1 className='section-title'>{t('choose-your-own')}</h1>
							</div>
							{/* <div className='section-options'>
								<select className='library-select'>
									<option value='animal'>Category</option>
									<option value='animal'>Animal</option>
									<option value='animal'>Animal</option>
									<option value='animal'>Animal</option>
									<option value='animal'>Animal</option>
								</select>
								<select className='library-select mobile-select-color'>
									<option value='animal'>Level</option>
									<option value='animal'>HSK2</option>
									<option value='animal'>HSK3</option>
									<option value='animal'>HSK4</option>
									<option value='animal'>HSK5</option>
								</select>
							</div> */}
						</div>

						<MobileLibraryItem
							items={items}
							showshorttext={true}
							showauthor={true}
						/>
					</div>
					<a className='view-more-lb' onClick={toStore}>
						{t('view_more')}
					</a>
				</section>
			</section>
		</>
	);
};

export default Library;
