import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/001.jpg";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog001 = () => {
	return (
		<div id='blogRead'>
            <h3>Engagement, engagement, engagement - how to make online classes fun!</h3><br />

            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>
            According to a research, the teaching environment plays a huge role and with the right environment, students in an online group can be both more motived and engaged than in a physical group.<br /><br />
If the social environment of the online group is will designed and students enjoy interacting in this way with their peers and teachers, an online group can be just as successful – or even more successful than a physical one, particularly if, in the face to face environment, there is not enough time for establishing interaction and/or students find it difficult to interact or participate in these environments.
<br /><br />
Thus engagement is key for either physical or online classes; development is proportional to quantity and quality of involvement and the effectiveness of any educational practice is directly related to the ability of that practice to increase student engagement.
<br /><br /><br /><br /></p>
        </div>
	);
};

function share() {
        navigator.share({url: window.location.href});
}

export default Blog001;