import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/004.jpg";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog004 = () => {
	return (
		<div id='blogRead'>
            <h3>Why Chinese is so hard</h3>
            
            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>
            Learning a foreign language like Chinese requires motivation (such as passing the HSK3 test within three months). This can be made more challenging without good teaching materials that are compelling, and support.
            <br /><br />
            Support can come in many forms, supportive learning communities help but also with technology.
            <br /><br />
            In Chinese, there are many characters and many appear similar such as 王 and 玉 but have different meanings – unless you equate ‘king’ with ‘jade’. These characters are formed by different radicals that may be difficult for some students to see.
            <br /><br />
            Characters that have one meaning in a standalone form, for example 腦 (brain) or 電 (electric) have a very different meaning when combined mean something different電腦 (computer). So it is important to know how characters combine to form words.
            <br /><br />
            Finally, there are also different ways to express the same word depending on where you are. For instance, 計算機 and 電腦 both mean ‘computer’.
            <br /><br />
            Technology can help you navigate this very complex space – when employed properly. More on that later.
            <br /><br /><br /><br /></p>
        </div>
	);
};

function share() {
    navigator.share({url: window.location.href});
}

export default Blog004;