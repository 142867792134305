import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import email from 'assets/images/@.svg';
import heart from 'assets/images/heart.svg';
import {
	HomeF1Image,
	HomeF2Image,
	HomeF3Image,
	HomeF4Image,
	HomeF5Image
} from "assets/images/HOME/home-features-images.js"
import picture from 'assets/images/picture.svg';
import voice from 'assets/images/voice.svg';
import './VerticalSlider.scss';

const getDimensions = (ele) => {
	const { height } = ele.getBoundingClientRect();
	const offsetTop = ele.offsetTop;
	const offsetBottom = offsetTop + height;

	return {
		height,
		offsetTop,
		offsetBottom,
	};
};

const scrollTo = (ele) => {
	ele.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
};

function VerticalSlider() {
	const [t, i18n] = useTranslation('translation');	
	const [visibleSection, setVisibleSection] = useState();
	const headerRef = useRef(null);
	const LinkOne = useRef(null);
	const LinkTwo = useRef(null);
	const LinkThree = useRef(null);
	const LinkFour = useRef(null);
	const LinkFive = useRef(null);

	const sectionRefs = [
		{ section: 'linkOne', ref: LinkOne },
		{ section: 'linkTwo', ref: LinkTwo },
		{ section: 'linkThree', ref: LinkThree },
		{ section: 'linkFour', ref: LinkFour },
		{ section: 'linkFive', ref: LinkFive },
	];

	useEffect(() => {
		const handleScroll = () => {
			const { height: headerHeight } = getDimensions(headerRef.current);
			const scrollPosition = window.scrollY + headerHeight;

			const selected = sectionRefs.find(({ section, ref }) => {
				const ele = ref.current;
				if (ele) {
					const { offsetBottom, offsetTop } = getDimensions(ele);
					return scrollPosition > offsetTop && scrollPosition < offsetBottom;
				}
			});

			if (selected && selected.section !== visibleSection) {
				setVisibleSection(selected.section);
			} else if (!selected && visibleSection) {
				setVisibleSection(undefined);
			}
		};

		handleScroll();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [visibleSection]);

	return (
		<div className='vertical-slider'>
			<div className='custom-container'>
				<div className='main-vertical-item'>
					<Row>
						<Col className='col-3 col-lg-4'>
							<div className='sticky-element'>
								<div className='top-sticky2'>
									<div className='process-btn-link'>
										<a href='#'>
											<img src={heart} alt='' />
										</a>
										<a href='#'>
											<img src={voice} alt='' />
										</a>
										<a href='#'>
											<img src={picture} alt='' />
										</a>
										<a href='#'>
											<img src={email} alt='' />
										</a>
									</div>

									<div className='vs-section-title'>{t('how_works')}</div>
								</div>
								<ul ref={headerRef} className=''>
									<li
										className={`header_link ${
											visibleSection === 'linkOne' ? 'selected' : ''
										}`}
										onClick={(e) => {
											e.preventDefault();
											scrollTo(LinkOne.current);
										}}
									>
										<a href='#' className='d-none d-lg-inline-block'>
											{t('upload_content')}
										</a>
									</li>
									<li
										className={`header_link ${
											visibleSection === 'linkTwo' ? 'selected' : ''
										}`}
										onClick={(e) => {
											e.preventDefault();
											scrollTo(LinkTwo.current);
										}}
									>
										<a href='#' className='d-none d-lg-inline-block'>
											{t('text_recognition')}
										</a>
									</li>
									<li
										className={`header_link ${
											visibleSection === 'linkThree' ? 'selected' : ''
										}`}
										onClick={(e) => {
											e.preventDefault();
											scrollTo(LinkThree.current);
										}}
									>
										<a href='#' className='d-none d-lg-inline-block'>
											{t('edit_text_output')}
										</a>
									</li>
									<li
										className={`header_link ${
											visibleSection === 'linkFour' ? 'selected' : ''
										}`}
										onClick={(e) => {
											e.preventDefault();
											scrollTo(LinkFour.current);
										}}
									>
										<a href='#' className='d-none d-lg-inline-block'>
											{t('add_post_details')}
										</a>
									</li>
									<li
										className={`header_link ${
											visibleSection === 'linkFive' ? 'selected' : ''
										}`}
										onClick={(e) => {
											e.preventDefault();
											scrollTo(LinkFive.current);
										}}
									>
										<a href='#' className='d-none d-lg-inline-block'>
											{t('ready_share')}
										</a>
									</li>
								</ul>
							</div>
						</Col>
						<Col className='col-9 col-lg-8'>
							<div id='linkOne' className='section-height' ref={LinkOne}>
								<Row className='custom-flex'>
									<Col className='phone-frame'>
									<img src={HomeF1Image} alt='' /><br /><br />
										<div className='process-item-info'>
											<h2>{t('upload_content')}</h2>
											<p>
												{t('upload_content_desc')}
											</p>
											<button className='vs-cta-button' onClick={toSignIn}>{t('try_free')}</button>
										</div>
										
									</Col>
								</Row>
							</div><br /><br /><br />
							<div id='linkTwo' className='section-height' ref={LinkTwo}>
								<Row className='custom-flex'>
									<Col className='phone-frame'>
									<img src={HomeF2Image} alt='' /><br /><br />
										<div className='process-item-info'>
											<h2>{t('text_recognition')}</h2>
											<p>
											{t('text_recognition_desc')}
											</p>
											<button class='vs-cta-button' onClick={toSignIn}>{t('try_free')}</button>
										</div>
										
									</Col>
								</Row>
							</div><br /><br /><br />
							<div id='linkThree' className='section-height' ref={LinkThree}>
								<Row className='custom-flex'>
									<Col className='phone-frame'>
									<img src={HomeF3Image} alt='' /><br /><br />
										<div className='process-item-info'>
											<h2>{t('edit_text_output')}</h2>
											<p>
											{t('edit_text_output_desc')}
											</p>
											<button class='vs-cta-button' onClick={toSignIn}>{t('try_free')}</button>
										</div>
										
									</Col>
								</Row>
							</div><br /><br /><br />
							<div id='linkFour' className='section-height' ref={LinkFour}>
								<Row className='custom-flex'>
									<Col className='phone-frame'>
									<img src={HomeF4Image} alt='' /><br /><br />
										<div className='process-item-info'>
											<h2>{t('add_post_details')}</h2>
											<p>
											{t('add_post_details_desc')}
											</p>
											<button class='vs-cta-button' onClick={toSignIn}>{t('try_free')}</button>
										</div>
										
									</Col>
								</Row>
							</div><br /><br /><br />
							<div id='linkFive' className='section-height' ref={LinkFive}>
								<Row className='custom-flex'>
									<Col className='phone-frame'>									
									<img src={HomeF5Image} alt='' /><br /><br />
										<div className='process-item-info'>
											<h2>{t('ready_share')}</h2>
											<p>
											{t('ready_share_desc')}
											</p>
											<button class='vs-cta-button' onClick={toSignIn}>{t('try_free')}</button>
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
}

function toSignIn() {
	window.open('https://story.ricciwawa.com/signin');
}

export default VerticalSlider;
