import React from 'react'
import { useTranslation } from "react-i18next";
import './CommonButton.scss'
import WhiteApple from 'assets/images/white-apple.svg';
import WhitePlay from 'assets/images/white-play.svg';
import toStore from 'functions/toStore.js';

function CommonButton() {
  const [t, i18n] = useTranslation('translation');

  return (
    
    <button className='hero-section-button d-flex align-items-center ' onClick={toStore}>
      <div className='d-flex align-items-center'>
        <img src={WhiteApple} alt='...' />
        <img src={WhitePlay} alt='...' />
      </div>
      <span className='me-2'>{t('download')}</span>
    </button>
  )
}

export default CommonButton