import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './richtext.module.css';

const TermsAndConditions = () => {
	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Terms and Conditions | Ricciwawa</title>
			</Helmet>
			<main className='custom-container section-wrapper'>
				<h1 className={styles.h1}>Terms of Service</h1>

				<section>
					<h2 className={styles.h2}>T.0 General Terms – All Users</h2>
					<p className={(styles.p, styles.semibold)}>T.0.1 Definitions</p>
					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}>NFT:</span>
						Non Fungible Token
					</p>

					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}>
							Qualified Educational Institution
						</span>
						: means a public or private school, tutorial or learning center that
						has been accredited by an authorized governmental agency within its
						applicable level and has the primary purpose of teaching its
						enrolled students.
					</p>
					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}>Ricciwawa Content</span>
						:all content, software, images, text, graphics, illustrations,
						logos, patents, trademarks, service marks, copyrights, photographs,
						audio, videos, music on and “look and feel” of the Services, and all
						intellectual property rights related thereto that are either owned
						or licensed by Makebell,
					</p>
					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}>User</span>: A user is anyone who
						access the Services. Users include, but are not limited to:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							Educators include, but is not limited to, teachers, tutors,
							teaching assistants, professors, lecturers, teaching at a
							Qualified Educational Institution. Educators can create accounts
							for their students on an individual or as a group (i.e. a ‘Team’)
						</li>
						<li className={styles.li}>
							Parents or Guardians who can create accounts for children under 13
							and can create Teams with their children
						</li>
						<li className={styles.li}>Students</li>
						<li className={styles.li}>
							Subscribers: anyone who accesses Creator content on either a paid
							or unpaid basis, and/or
						</li>
						<li className={styles.li}>
							Creators: anyone who creates Content on the Platform to engage
							with
						</li>
					</ul>

					<p className={(styles.p, styles.semibold)}>
						The above designations are not mutually exclusive, for example a
						Parent can also be a Creator.
					</p>
				</section>

				<section>
					<h2 className={styles.h2}>T.1 Your Relationship With Us</h2>
					<p className={styles.p}></p>
					<p className={(styles.p, styles.semibold)}>
						T.1 Your Relationship With Us
						<span className={styles.bold}>“Platform”</span>, which is provided
						by Makebell Ltd.{' '}
					</p>

					<p className={(styles.p, styles.semibold)}>
						You are reading the terms of service (the
						<span className={styles.bold}>“Terms”</span> ), which govern the
						relationship and serve as an agreement between you and us and set
						forth the terms and conditions by which you may access and use the
						Platform and our related websites, services, applications, products
						and content (collectively, the{' '}
						<span className={styles.bold}>“Services”</span>). Our Services are
						provided for private, non-commercial use. For purposes of these
						Terms,
						<span className={styles.bold}>“you”</span>
						and
						<span className={styles.bold}>“your”</span>
						means you as the user of the Services. , which is provided by
						Makebell Ltd.
					</p>
					<p className={(styles.p, styles.semibold)}>
						The Terms form a legally binding agreement between you and us.
						Please take the time to read them carefully.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>T.2 Accepting the Terms</h2>
					<p className={styles.p}>
						By accessing or using our Services, you confirm that you can form a
						binding contract with Makebell, that you accept these Terms and that
						you agree to comply with them.{' '}
					</p>
				</section>

				<section>
					<h3 className={styles.h3}>2.1 Entire Agreement</h3>
					<p className={styles.p}>
						You agree that these Terms are the complete and exclusive statement
						of the mutual understanding between you and us, and that it
						supersedes and cancels all previous written and oral agreements,
						communications and other understandings relating to the subject
						matter of this Agreement and that your access to and use of our
						Services is also subject to our:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}> Privacy Policy</li>
						<li className={styles.li}> Intellectual Property Policy</li>
						<li className={styles.li}> Community Guidelines</li>
						<li className={styles.li}>
							Creator’s Agreement (if you are a Creator) and
						</li>
						<li className={styles.li}>
							Any applicable Supplemental Agreements.
						</li>
					</ul>

					<p className={styles.p}>
						You can accept the Terms by accessing or using our Services. You
						understand and agree that we will treat your access or use of the
						Services as acceptance of the Terms from that point onwards.
					</p>
					<p className={styles.p}>
						{' '}
						You should print off or save a local copy of the Terms for your
						records.
					</p>
				</section>

				<section>
					<h2 className={styles.h2}>T.3 Your Account with Us</h2>
					<p className={styles.p}>
						To access or use some of our Services, you must create an account
						with us.
					</p>
				</section>
				<section>
					<h3 className={styles.h3}>T.3.1 Account creation</h3>
					<p className={styles.p}>
						When you create this account, you must provide accurate and
						up-to-date information. It is important that you maintain and
						promptly update your details and any other information you provide
						to us, to keep such information current and complete.
					</p>
					<p className={styles.p}>
						To create an account you affirm that you have not been previously
						suspended or removed from the Services and are:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							<span className={styles.bold}>Fully able and competent</span>
							to enter into the terms, conditions, obligations, affirmations,
							representations, and warranties set forth in these Terms, and to
							abide by and comply with these Terms
						</li>

						<li className={styles.li}>
							<span className={styles.bold}>At least 13 years old</span>
							or have your Parent’s Guardian’s or an Educator’s permission. If
							you are considered a minor in your country, you represent that you
							have your parent or guardian’s permission to use the Services.
							Please have them read these Terms with you.
						</li>

						<ul className={styles.ul}>
							<li className={styles.li}>
								If you are a parent or legal guardian of a minor in your
								country, by allowing your child to use the Services, you are
								subject to the Terms and are responsible for your child’s
								activity on the Services
							</li>
							<li className={styles.li}>
								If you are an Educator allowing your students to use the
								Services, you are subject to the Terms and are responsible for
								informing your students’ parents you have provided their
								children with access to the Services
							</li>
							<li className={styles.li}>
								If we learn that someone under the relevant age specified above
								is using the Services, we will terminate that user’s account.
							</li>
						</ul>

						<li className={styles.li}>
							To be a Creator, you must be at least 18 years old or have your
							Parent’s Guardian’s or an Educator’s permission.
						</li>
					</ul>

					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}>No joint venture:</span>
						You also acknowledge and agree that you are not a joint venture of
						Makebell Limited, and you do not have any authority of any kind to
						bind us in any respect whatsoever.
					</p>

					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}>No conflict of interest: </span>
						You also acknowledge and agree that you are not an employee, agent,
						or partner of Makebell Limited, and you do not have any authority of
						any kind to bind us in any respect whatsoever.
					</p>
				</section>

				<section>
					<h3 className={styles.h3}>T.3.2 Access as a Business or entity</h3>
					<p className={styles.p}>
						If you are accessing or using the Services on behalf of a business
						or entity, then (a) “you” and “your” includes you and that business
						or entity, (b) you represent and warrant that you are an authorized
						representative of the business or entity with the authority to bind
						the entity to these Terms, and that you agree to these Terms on the
						entity’s behalf, and (c) your business or entity is legally and
						financially responsible for your access or use of the Services as
						well as for the access or use of your account by others affiliated
						with your entity, including any employees, agents or contractors.
					</p>
				</section>
				<section>
					<h3 className={styles.h3}>
						T.3.3 Educator’s Additional Terms of Use
					</h3>
					<p className={styles.p}>
						As a safety precaution Educators may be asked to provide us with
						proof of their teaching certification and employment at a school.
					</p>
					<p className={styles.p}>
						In addition to these Terms of Use, if you are an Educator using
						Ricciwawa, you acknowledge and agree that:
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}>
							You, or the educational institution you work for, will obtain any
							necessary parent or guardian consent for a student to use
							Ricciwawa prior to inviting a student to use Ricciwawa in
							accordance with applicable laws
						</li>
						<li className={styles.li}>
							You will only use, and only permit your students to use
							Ricciwawa(and all content and media incorporated therein) for
							educational purposes;
						</li>
					</ul>

					<p className={styles.p}>
						The Term of a Ricciwawa account for an Educator will start on the
						date access is granted and end on the earliest of:
					</p>

					<ul className={styles.ul}>
						<li className={styles.li}> Three (3) years thereafter;</li>
						<li className={styles.li}>
							The date when you no longer qualify as an Educator
						</li>
					</ul>
				</section>
				<section>
					<h3 className={styles.h3}>T.3.4 Your responsibilities</h3>
					<p className={styles.p}>
						It is important that you keep your account password confidential and
						that you do not disclose it to any third party. If you know or
						suspect that any third party knows your password or has accessed
						your account, you must notify us immediately at: info@ricciwawa.com.
					</p>

					<p className={styles.p}>
						You agree that you are solely responsible (to us and to others) for
						the activity that occurs under your account.
					</p>
					<p className={styles.p}>
						You must immediately notify us of any unauthorized uses of your
						account or any other breaches of security. Makebell will not be
						liable for any acts or omissions by you, including any damages of
						any kind incurred as a result of such acts or omissions.
					</p>
				</section>

				<section>
					<h4 className={styles.h4}>T.3.4.1What you should do</h4>
					<p className={styles.p}>
						As a user, you can help prevent unauthorized access to your account
						and information by choosing a strong and unique password which is
						stored in a password manager. If you have any questions about the
						security of your personal information, you can contact us at
						info@ricciwawa.com.
					</p>
				</section>

				<section>
					<h4 className={styles.h4}>T.3.4.2 Security experts or researchers</h4>
					<p className={styles.p}>
						As a user, you can help prevent unauthorized access to your account
						and information by choosing a strong and unique password which is
						stored in a password manager. If you have any questions about the
						security of your personal information, you can contact us at
						info@ricciwawa.com.If you are a security expert or researcher and
						you believe that you have discovered a security-related issue with
						the Services, we appreciate your help in disclosing the issue to us
						responsibly.
					</p>
				</section>

				<section>
					<h3 className={styles.h3}>T.3.5 Creators</h3>
					<p className={styles.p}>
						Please refer to the Creator Agreement for additional terms
						applicable to Creators
					</p>
				</section>

				<section>
					<h3 className={styles.h3}>T.3.6 Subscribers</h3>
					<p className={styles.p}>
						Anyone can become a Subscriber to one or more Creator(s).
					</p>
					<p className={styles.p}>
						To pay for Creator content provided on a fee basis, simply add your
						preferred payment method.
					</p>
					<p className={styles.p}>
						We are not required to allow you to be a Subscriber of any
						particular Creator. Creators’ memberships vary and we have limited
						control over the quality and specific offerings.
					</p>
				</section>
				<section>
					<h4 className={styles.h4}>T.3.6.1 Screening of Creator</h4>
					<p className={styles.p}>
						We attempt to screen for fraudulent Creator content, but cannot
						guarantee the identity of Creators or the validity of any claims
						they make. We appreciate your help reporting suspicious Creator
						pages so we can keep the Services safe.
					</p>
				</section>
				<section>
					<h4 className={styles.h4}>T.3.7 Disabling of your account</h4>
					<p className={styles.p}>
						We reserve the right to disable your user account at any time,
						including if you have failed to comply with any of the provisions of
						these Terms or our Community Guidelines, or if activities occur on
						your account which, in our sole discretion, would or might cause
						damage to or impair the Services or infringe or violate any third
						party rights, violate any applicable laws or regulations.
					</p>
				</section>
				<section>
					<h4 className={styles.h4}>T.3.7.1 Hacked Accounts</h4>
					<p className={styles.p}>
						Makebell reserves the right to terminate, suspend or restrict your
						access to any Account(s) if there is reasonable suspicion by us that
						the person logged into your Account(s) is not you or if we suspect
						that the Account(s) have been or will be used for any illegal,
						fraudulent, or unauthorized purposes. Under no circumstances shall
						Makebell or indemnified persons in accordance with these Terms be
						responsible or liable for any direct or indirect losses (including
						loss of profits, business or opportunities), damages or costs
						suffered by you or any other person or entity due to any such
						termination, suspension or restriction of access to any Account(s).
					</p>
				</section>
				<section>
					<h4 className={styles.h4}>T.3.7.2 Unsubscribe from the Services</h4>
					<p className={styles.p}>
						{' '}
						If you no longer want to use our Services again, and would like your
						account deleted, contact us at: info@ricciwawa.com. We will provide
						you with further assistance and guide you through the process. Once
						you choose to delete your account, you will not be able to
						reactivate your account or retrieve any of the content or
						information you have added.
					</p>
				</section>
				<section>
					<h2 className={styles.h2}>T.4 Acceptable use </h2>
					<p className={styles.p}>
						Your access to and use of the Services is subject to these Terms and
						all applicable laws and regulations.
					</p>
				</section>

				{/* <section>
					<h2 className={styles.h2}></h2>
					<h3 className={styles.h3}></h3>
					<h4 className={styles.h4}></h4>
					<p className={styles.p}></p>
					<p className={(styles.p, styles.semibold)}>
						<span className={styles.bold}></span>
					</p>
					<ul className={styles.ul}>
						<li className={styles.li}></li>
					</ul>
				</section> */}
			</main>
		</>
	);
};

export default TermsAndConditions;
