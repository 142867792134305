import React from 'react';
import "./blogRead.css";
import image from "assets/images/BLOG/003.jpg";
import clock from 'assets/images/clock.svg';
import author from 'assets/images/author2.png';
import shareIcon from 'assets/images/BLOG/share.svg';

const Blog003 = () => {
	return (
		<div id='blogRead'>
            <h3>Reimagining Education (2)</h3>
            <img src={author} alt='...' className='author' /><div className='info'>Ron Yu<br />10 March, 2022</div>
            <div className='duration'>
                <img src={clock} alt='...'/>
                3 min read
            </div>
            <img src={shareIcon} alt='...' className='shareIcon' onClick={share}/>
            <img src={image}></img>
            <p>
            One of the challenges of online teaching is feedback.<br /><br />
            In a physical classroom, it is relatively easy for an instructor to assess whether students are engaged or not by simply looking at students – assuming the instructor can clearly see all the students.
            <br /> <br />
            In theory, getting student feedback on any number of online videoconferencing platforms such as Zoom, Google Meet, Microsoft Teams, Cisco Webex, etc., should not be a problem given these systems provide facilities to view students, online chat and other feedback mechanisms.
            <br /> <br />
            But in practice, teachers focused on giving lessons cannot view many of their students because there may be too many students to watch on screen, technical reasons such as bad Internet connections, or simply because it is too difficult to lecture, manage accompanying online presentations and watch students.
            <br /> <br />
            This means that for instructors to get proper feedback in online teaching they would need to rely on other mechanisms. Slowly but surely, these are coming – and some do not require the instructor to be actively lecturing, in other words the feedback can come from outside the lecture.
            <br /> <br />
            More on this in another post.
            <br /><br /><br /><br /> </p>
        </div>
	);
};

function share() {
    navigator.share({url: window.location.href});
}

export default Blog003;